import React, {useState, useEffect} from 'react'
import Masonry from 'react-masonry-css'
import Block from './block'
import styles from './grid.module.scss'

const Grid = (props) => {
  const breakpointColumnsObj = {
    default: 3,
    1024: 2,
    768: 1
  }
  
  const [activeFilter, setActiveFilter] = useState('all')
  const [posts, setPosts] = useState(props.nodes)

  // Handles initial set and filtering the data
  useEffect(() => {
    // Filter in URL
    if (window.location.hash) {
      let f = window.location.hash.substring(1)
      document.getElementById(f).classList.add(styles.active)
      setActiveFilter(f)
      setPosts(props.nodes.filter(filterPosts))
    }
    // All is selected
    else if (activeFilter === 'all') {
      document.getElementById('all').classList.add(styles.active)
      setPosts(props.nodes)
    }
    // Anything else is selected
    else {
      setPosts(props.nodes.filter(filterPosts))
    }
  }, [activeFilter])

  // Handles the UI updates of clicking a filter
  // filter: name of the filter (e.g. 'Interviews')
  // event.target: filter dom element that was pressed
  function handleFilter(filter, event) {

    let el = event.target

    // Removes filter in url if there is one
    if (window.location.hash) {
      window.location.hash = ''
    }

    // Not yet selected
    if (activeFilter !== filter) {
      document.getElementById(activeFilter).classList.remove(styles.active)
      el.classList.add(styles.active);
      setActiveFilter(filter)     
    }
  }

  // Helper function to filter posts according to active filter
  // Passed in as param to array filter function
  function filterPosts(post) {
    let found = false;
    post.categories.forEach(cat => {
      if (cat.title.toLowerCase() === activeFilter) {
        found = true
      }
    })
    return found
  }
  
  return (
    <div className={styles.root}>
      <div className={styles.filters}>
        <h5>Filters</h5>
        <div className={styles.filter_list} id={'list'}>
          <h3
            className={'filter'}
            id={'all'}
            onClick={(e) => handleFilter('all', e)}
          >
            all
          </h3>
          {props.filters && props.filters.map(filter => (
            <h3 
              className={'filter'} 
              id={filter.title.toLowerCase()}
              key={filter.title.toLowerCase()} 
              onClick={(e) => handleFilter(filter.title.toLowerCase(), e)}
            >
                {filter.title}
            </h3>
          ))}
        </div>
      </div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className={styles.grid}
        columnClassName={styles.column}
      >
        {posts.map(node => (
          <Block {...node} key={node.id} />
        ))}
      </Masonry>
    </div>
  )
}

export default Grid
