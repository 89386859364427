import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import Icon from './icon/index'
import styles from './block.module.scss'

function Block (props) {
  const {color} = props
  const attrs = color && color.hex ? {style: {backgroundColor: color.hex}} : {style: {backgroundColor: '#111111'}}
  let content = null
  let icon = null
  let caption = null

  //--- Icon selector ---//
  if (props.source === 'Twitter') {
    icon = <Icon symbol={'twitter'}></Icon>
  }

  if (props.caption) {
    caption = 
      <div className={styles.caption}>
        <h6 >{props.caption}</h6>
        <Icon symbol={'arrow'}></Icon>
      </div>
  }

  //--- Which media to render ---//
  // Media uploaded to CMS
  if (props.mainImage && props.mainImage.asset) {
    content = 
      <div className={styles.media}>
        <img src={imageUrlFor(buildImageObj(props.mainImage)).url()} alt={'image'}/>
        {caption || icon}
      </div>
  }
  // Media from external source
  else if (props.media_url) {
    if (props.media_url.endsWith('.mp4')) {
      content = 
      <div className={styles.media}>
        <video loop="true" autoplay="autoplay" muted playsinline>
          <source src={props.media_url} type="video/mp4"/>
        </video>
        {caption || icon}
      </div>
    }
    else {
      content = 
      <div className={styles.media}>
        <img src={props.media_url}/>
        {caption || icon}
      </div>
    }
  }

  // Text only
  else if (props.text) {
    content = 
      <div className={styles.text} {...attrs}>
        <h3>{props.text}</h3>
        {caption || icon}
      </div>
  }

  return (
    <a href={props.link} className={styles.link} target='_blank'>
      {content}
    </a>
  )
}

export default Block
