import React from 'react'
import {graphql} from 'gatsby'
import {mapEdgesToNodes} from '../lib/helpers'

import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import Footer from '../components/footer'
import Grid from '../components/grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Carousel from '../components/carousel'

import blend from '../images/blend.mp4'
import breathe from '../images/breathe.mp4'
import headroom from '../images/headroom.mp4'
import hearing from '../images/hearing.mp4'
import miraj from '../images/miraj.mp4'
import office from '../images/office.mp4'
import publicapp from '../images/publicapp.mp4'
import quotes from '../images/quotes.mp4'
import slice from '../images/slice.mp4'
import survey from '../images/survey.mp4'
import turning from '../images/turning.mp4'
import water from '../images/water.mp4'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      description
      logo {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      keywords
    }
    heroes: allSanityHero(
      limit: 10
    ) {
      edges {
        node {
          color
          image { 
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
        }
      }
    }
    categories: allSanityCategory(
      limit: 10
    ) {
      edges {
        node {
          title
        }
      }
    }
    posts: allSanityPost(
      limit: 100
      sort: {fields: [created], order: DESC}
    ) {
      edges {
        node {
          id
          source
          link
          created
          text
          color {
            name
            hex
          }
          categories {
            title
          }
          media_url
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          caption
        }
      }
    }
  }
`

const IndexPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site

  // Hero images
  const heroNodes = (data || {}).heroes
  ? mapEdgesToNodes(data.heroes)
  : []
  // Posts
  const categoryNodes = (data || {}).posts
    ? mapEdgesToNodes(data.categories)
    : []
  // Filter categories
  const postNodes = (data || {}).posts
  ? mapEdgesToNodes(data.posts)
  : []
  
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  console.log(miraj)

  return (
    <Layout siteLogo={site.logo} id={'test'}>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      {heroNodes && (
          <Carousel id={'caro'} nodes={heroNodes} showCTA={true}/>
      )}     
      <Container>
        {postNodes && categoryNodes && (
          <Grid nodes={postNodes} filters={categoryNodes} />
        )}
        <Footer />
      </Container>
      
    </Layout>
  )
}

export default IndexPage
